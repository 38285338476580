import axios from 'axios';

export class QuestionsService {
  async fetchAll() {
    return (await axios.get('/questions/new')).data;
  }

  async fetchQuestionByIds(ids) {
    return (await axios.post('/questions/new/all/ids', ids)).data;
  }

  async fetchAllForCategory(categoryId) {
    // return (await axios.get(`/questions/category/${categoryId}/all`)).data;
    return (
      await axios.get(`/questions/new/category/${categoryId}/all/questions`)
    ).data;
  }

  async fetchOne(id, sortBy, query) {
    // return (await axios.get('/questions/new/' + id + '/inactive')).data;

    let sort = sortBy === 'true' ? 'asc' : sortBy;
    let url = `/questions/new/${id}/inactive`;
    if (query) {
      url += `?sort_order=${sort}&${query}`;
    }

    return (await axios.get(url)).data;
  }

  async create(question) {
    return (await axios.post('/questions/new', question)).data;
  }

  async delete(question) {
    return (await axios.delete('/questions/new/' + question.id)).data;
  }

  async update(question) {
    return (await axios.patch('/questions/new', question)).data;
  }

  async getPassageQuestions(isInactive = false) {
    return (
      await axios.get(
        '/questions/new/for-passage' + (isInactive ? '?active=0' : '')
      )
    ).data;
  }

  async getPassageQuestionsByQuestionId(id) {
    return (await axios.get('/questions/new/passage/' + id)).data;
  }

  async fetchAllComments() {
    return (await axios.get('/question-comments')).data;
  }

  async fetchComments(id) {
    return (await axios.get('/question-comments/all/' + id)).data;
  }

  async deleteComment(comment) {
    return (await axios.delete('/question-comments/' + comment.id)).data;
  }

  async updateComment(comment) {
    return (await axios.patch('/question-comments', comment)).data;
  }

  async paginate(
    isActive = true,
    currentPage = 0,
    perPage = 10,
    searchText = '',
    query,
    average = false
  ) {
    // let url = `/questions/new/paginated?page=${currentPage}&limit=${perPage}`;
    let url = isActive
      ? `/questions/new/paginated?page=${currentPage}&limit=${perPage}`
      : `/questions/new/paginated/inactive?page=${currentPage}&limit=${perPage}`;
    if (searchText) {
      url += `&query=${searchText}`;
    }
    if (query) {
      url += `&${query}`;
    }
    if (average) {
      url += `&average=${average}`;
    }
    return (await axios.get(url)).data;
  }

  async fetchTotalPlayedCount(questionId) {
    return (await axios.get(`/scores/new/${questionId}/play-count`)).data;
  }

  async fetchQuestionsBySubcategory(categoryId, subcategoryId) {
    return (
      await axios.get(
        `questions/new/subcategory?category=${categoryId}&subcategory=${subcategoryId}&question-type=choices&sort=false`
      )
    ).data;
  }
}
