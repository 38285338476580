<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row>
          <v-col class="d-flex justify-space-between align-center">
            <v-card-title style="color: #020819">
              Popup Notification Details</v-card-title
            >
            <v-btn @click="$router.back()" dark color="primary" elevation="0"
              >Back</v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            v-for="(content, index) in popupNotification.content"
            :key="index"
          >
            <v-card outlined>
              <v-img
                v-if="content.image"
                :src="content.image"
                width="100%"
                max-height="150px"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    style="background-color: #f5f5f5"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-card-title class="subtitle-1">
                {{ content.title }}
              </v-card-title>
              <v-card-text class="caption">
                {{ content.description }}</v-card-text
              >
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-6" no-gutters>
          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>One Time / Recurring : </b>

              <span class="ml-2">{{
                popupNotification.oneTime ? 'One Time' : 'Recurring'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Type : </b>

              <span class="ml-2">{{
                popupNotification.type === 0
                  ? 'All Users'
                  : popupNotification.type === 1
                  ? 'Specific Users'
                  : popupNotification.type === 2
                  ? 'Premium Users'
                  : ''
              }}</span>
            </v-card-text>
          </v-col>

          <v-col v-if="popupNotification.type === 1" cols="12">
            <v-card-text class="d-flex align-center">
              <b>Specific Users : </b>

              <span class="ml-2">{{
                popupNotification.specificUserList.join(', ')
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Start Date : </b>

              <span class="ml-2">{{
                formatDate(popupNotification.schedule.startDate)
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>End Date : </b>

              <span class="ml-2">{{
                formatDate(popupNotification.schedule.endDate)
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Active : </b>

              <span class="ml-2">{{
                popupNotification.active ? 'Active' : 'Inactive'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>IOS : </b>

              <span class="ml-2">{{
                popupNotification.ios ? 'Yes' : 'No'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Android : </b>

              <span class="ml-2">{{
                popupNotification.android ? 'Yes' : 'No'
              }}</span>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="d-flex align-center">
              <b>Web : </b>

              <span class="ml-2">{{
                popupNotification.web ? 'Yes' : 'No'
              }}</span>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <data-table
        :loader="loadData"
        :headers="headers"
        title="Popup Stats"
        :show-search="false"
        :hasElevation="false"
        :allow-add="false"
        :allow-filters="false"
        @done="$router.back()"
        :delete-handler="null"
        :edit-handler="null"
      >
        <template #date="{ item }">
          {{ formatDate(item.date) }}
        </template>
      </data-table>
    </v-card>

    <loading-dialog v-model="loading" message="Please Wait..." />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import LoadingDialog from '../../components/LoadingDialog';
import { PopupNotificationsService } from '@/services/popup-notifications-service';
import DataTable from '../../components/DataTable';

export default {
  name: 'ResourceDetails',
  data() {
    return {
      loading: false,
      dataLoading: true,
      service: new PopupNotificationsService(),

      popupNotification: null,
      popupStats: null,
      headers: [
        {
          text: 'Date',
          value: 'date',
          sortable: true
        },
        {
          text: 'Open Click Count',
          value: 'openClickCount',
          sortable: true
        },
        {
          text: 'Close Click Count',
          value: 'closeClickCount',
          sortable: true
        }
      ]
    };
  },

  components: {
    LoadingDialog,
    DataTable
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format('MMMM D, YYYY');
    },

    async loadData() {
      this.dataLoading = true;
      const popupId = this.$route.query.id;
      if (popupId) {
        this.popupNotification = await this.service.fetchOne(popupId);
        this.popupStats = await this.service.fetchPopupStats({
          id: popupId,
          start_date: dayjs(this.popupNotification.schedule.startDate).format(
            'YYYY-MM-DD'
          ),
          end_date: dayjs(this.popupNotification.schedule.endDate).format(
            'YYYY-MM-DD'
          )
        });
        this.dataLoading = false;
        return this.popupStats;
      } else {
        this.$router.push('/popup-notifications');
      }
    }
  }
};
</script>
<style scoped></style>
