<template>
  <SimpleForm
    :onSubmit="submit"
    :style="[isModal && { margin: 0, width: '100%' }]"
    @done="isModal ? close() : $router.back()"
  >
    <div class="span-2 mb-3 flex justify-space-between">
      <v-btn
        color="primary"
        elevation="0"
        v-show="passageQuestion.previous"
        @click="reloadPage('/passage-question?id=' + passageQuestion.previous)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <p class="form__title flex-grow-1">
        {{ isEdit ? 'Update Passage Question' : 'Add a new Passage Question' }}
      </p>

      <v-btn
        color="primary"
        elevation="0"
        v-show="passageQuestion.next"
        @click="reloadPage('/passage-question?id=' + passageQuestion.next)"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>

    <v-card v-if="flagPassageQuestion" class="span-2 mb-5 pa-4">
      <v-card-title>Flag Reason</v-card-title>
      <p class="body-1 px-4">{{ flagPassageQuestion.reason }}</p>
      <div></div>
    </v-card>

    <v-checkbox
      v-model="isActiveCategory"
      dense
      label="Is this passage question for active category?"
      class="span-2"
      @change="
        loadQuestions();
        loadCategories();
      "
    />

    <v-text-field
      v-model="passageQuestion.heading"
      dense
      label="Question Title"
      :rules="[required('You must write a question title')]"
      class="span-2"
      outlined
    />

    <div class="span-2">
      <v-textarea
        v-model="passageQuestion.passage"
        dense
        :rules="[required('You must write a question passage')]"
        label="Question Passage"
        class="span-2"
        outlined
        height="400"
      />
    </div>

    <div class="flex span-2 mb-6">
      <v-checkbox
        v-model="passageQuestion.premium"
        dense
        label="Premium"
        hide-details
      />
      <v-checkbox
        v-model="passageQuestion.active"
        dense
        label="Active"
        hide-details
        class="ml-10"
      />
    </div>

    <v-select
      v-model="passageQuestion.category"
      :items="categories"
      :loading="loadingCategories"
      :rules="[required('Select a category')]"
      dense
      item-text="name"
      item-value="id"
      label="Select Category"
      outlined
      return-object
      @change="subCategories = passageQuestion.category.subCategories"
    />

    <v-select
      v-model="passageQuestion.sub_category"
      :disabled="!passageQuestion.category"
      :items="subCategories"
      :rules="[required('Select a sub-category')]"
      dense
      item-text="name"
      label="Select Subcategory"
      outlined
      return-object
    />

    <div class="span-2 mb-10">
      <h3 class="mb-4">Generate AI Question</h3>
      <div class="span-2">
        <span class="d-flex justify-space-between items-center">
          <v-select
            v-model="selectedPromptTemplate"
            :items="promptTemplates"
            :rules="[required('Select a prompt')]"
            dense
            item-text="text"
            item-value="value"
            label="Select Prompt"
            outlined
            hide-details
            :disabled="generateAiQuestionsLoading"
          />

          <v-btn
            elevation="0"
            color="primary"
            class="ml-4"
            @click="generateAiQuestion(selectedPromptTemplate)"
            :disabled="
              generateAiQuestionsLoading ||
              !passageQuestion.passage ||
              passageQuestion.passage.length < 20 ||
              !selectedPromptTemplate
            "
          >
            <v-progress-circular
              v-if="generateAiQuestionsLoading"
              indeterminate
              color="primary"
              size="20"
            ></v-progress-circular>

            <span v-else>{{
              aiGeneratedQuestion ? 'Regenerate' : 'Generate'
            }}</span>
          </v-btn>
        </span>
      </div>

      <div class="span-2 mt-4" v-if="aiGeneratedQuestion">
        <h3>AI Generated Question:</h3>
        <div class="d-flex justify-space-between items-center span-2">
          <span class="py-2"> {{ aiGeneratedQuestion.question }} </span>

          <v-btn
            elevation="0"
            color="primary"
            class="ml-2"
            @click="proceedQuestion"
            >Proceed</v-btn
          >
        </div>
      </div>
    </div>

    <div class="d-flex justify-space-between span-2">
      <h4>Questions</h4>
      <div>
        <v-btn
          v-if="passageQuestion.questions.length < 10"
          elevation="0"
          color="primary"
          class="mr-2"
          outlined
          @click="showQuestionDialog = true"
        >
          Create New Question
        </v-btn>
        <v-btn
          v-if="passageQuestion.questions.length < 10"
          elevation="0"
          color="primary"
          @click="addQuestion()"
        >
          <v-icon small>mdi-plus</v-icon>
          Add
        </v-btn>
      </div>
    </div>

    <div class="span-2 my-3">
      <div
        v-for="(question, i) of passageQuestion.questions"
        :key="i"
        class="span-2"
      >
        <v-autocomplete
          v-model="question.question"
          :label="'Select Question # ' + (i + 1)"
          item-text="statement"
          item-value="id"
          return-object
          :items="questions"
          :loading="loadingQuestions"
          class="span-2"
          :rules="[
            required('Select a question'),
            passageQuestionsValidator(question, passageQuestion.questions)
          ]"
          outlined
          dense
          :disabled="loadingQuestions"
        >
          <template v-slot:append-outer>
            <a
              v-if="question.question"
              :href="`/question?id=${question.question.id}`"
              target="_blank"
              class="text-decoration-none mx-2"
            >
              <v-icon color="blue">mdi-eye</v-icon>
            </a>

            <v-icon
              @click="removeQuestion(i)"
              v-if="passageQuestion.questions.length > 1"
              color="red"
              >mdi-delete
            </v-icon>
          </template>
        </v-autocomplete>
      </div>
    </div>

    <div class="mb-4 d-flex justify-space-between items-center span-2">
      <h3>Select Media</h3>
      <v-btn
        outlined
        elevation="0"
        color="primary"
        @click="openMediaPickerModal"
        >Select
      </v-btn>
    </div>
    <MediaPicker
      allowedType="image"
      :selectedData="selectedData"
      @SetNewSelectedData="setSelectedData"
    />

    <div class="span-2 carousel-display">
      <h4
        class="mt-3 text-center font-weight-light"
        v-if="
          selectedData.images.length <= 0 &&
          selectedData.videos.length <= 0 &&
          selectedData.pdfs.length <= 0
        "
      >
        No Media Selected
      </h4>

      <h2 class="mt-2 mb-3" v-if="selectedData.images.length === 1">Image</h2>
      <h2 class="mt-2 mb-3" v-if="selectedData.images.length > 1">Images</h2>
      <v-carousel
        :show-arrows="selectedData.images.length > 1"
        v-if="selectedData.images.length > 0"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item
          v-for="(item, i) in selectedData.images"
          :key="i"
          :src="item"
        >
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3" v-if="selectedData.videos.length === 1">Video</h2>
      <h2 class="mt-2 mb-3" v-if="selectedData.videos.length > 1">Videos</h2>
      <v-carousel
        :show-arrows="selectedData.videos.length > 1"
        v-if="selectedData.videos.length > 0"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.videos" :key="i">
          <video
            style="width: 100%; height: 100%"
            width="auto"
            height="auto"
            :src="item"
            controls
          />
        </v-carousel-item>
      </v-carousel>

      <h2 class="mt-2 mb-3 float-start" v-if="selectedData.pdfs.length === 1">
        PDF
      </h2>
      <h2 class="mt-2 mb-3 float-start" v-if="selectedData.pdfs.length > 1">
        PDFs
      </h2>

      <a
        v-if="selectedData.pdfs.length > 0"
        :href="selectedData.pdfs[displayedPdfIndex]"
        class="text-center float-end text-decoration-none"
        target="_blank"
      >
        <v-btn small class="mt-3" elevation="0" color="black">
          <v-icon size="20" color="white"> mdi-arrow-expand-all</v-icon>
        </v-btn>
      </a>

      <v-carousel
        :show-arrows="selectedData.pdfs.length > 1"
        v-if="selectedData.pdfs.length > 0"
        @change="pdfChange"
        hide-delimiters
        style="width: 100%; height: fit-content"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-left
          </v-icon>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-icon
            style="padding: 10px; background-color: white; border-radius: 50%"
            color="#4396e4"
            v-bind="attrs"
            v-on="on"
            >mdi-arrow-right
          </v-icon>
        </template>

        <v-carousel-item v-for="(item, i) in selectedData.pdfs" :key="i">
          <iframe style="width: 100%; height: 100%" :src="item" />
        </v-carousel-item>
      </v-carousel>
    </div>

    <loading-dialog v-model="loading" message="Loading Passage Question Data" />

    <v-dialog
      :width="$vuetify.breakpoint.mdAndUp ? '800px' : '90%'"
      v-model="showQuestionDialog"
    >
      <QuestionForm
        is-modal
        :initial-data="passageQuestion.question"
        @close="closeModal"
      />
    </v-dialog>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';

import { required, passageQuestionsValidator } from '@/utils/validators';
import { QuestionsService } from '@/services/questions-service';
import LoadingDialog from '../../components/LoadingDialog';
import { PassageQuestionsService } from '@/services/passage_questions-service';
import { storage } from '@/plugins/firebase';
import MediaPicker from '../../components/media/MediaPicker';
import QuestionForm from '@/components/questions/QuestionForm.vue';
import { CategoryService } from '@/services/category-service';
import { PassagePromptsService } from '@/services/passage-prompts-service';

async function uploadWithMessage(context, list, message, type) {
  context.changeLoadingMessage(message + ' ...');

  const newList = [];
  for (const item of list) {
    const fileName =
      item.filename + '~' + new Date().getTime() + '' + item.fileExtension;
    let reference = storage.ref(type + '/' + fileName);
    let task = reference.put(item.file);
    await task
      .then(async () => {
        newList.push(await storage.ref(type).child(fileName).getDownloadURL());
      })
      .catch((e) => window.console.log('uploading image error => ', e));
    context.changeLoadingMessage(
      message + ': ' + item.filenameWithoutExtension
    );
  }

  return newList;
}

export default {
  name: 'PassageQuestionFormComponent',
  components: { LoadingDialog, SimpleForm, MediaPicker, QuestionForm },
  computed: {
    uploadedImages() {
      return (this.passageQuestion?.images || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    }
  },
  data: () => ({
    isActiveCategory: true,
    displayedPdfIndex: null,
    showQuestionDialog: false,
    selectedData: {
      images: [],
      videos: [],
      pdfs: []
    },
    isEdit: false,
    passageQuestion: {
      questions: [{}]
    },
    loading: false,
    questions: [],

    promptTemplates: [],
    selectedPromptTemplate: null,
    aiGeneratedQuestion: null,
    generateAiQuestionsLoading: false,

    service: new PassageQuestionsService(),
    questionsService: new QuestionsService(),
    passagePromptsService: new PassagePromptsService(),
    loadingQuestions: false,

    images: [],

    imagesToBeDeleted: [],

    flagPassageQuestion: null,

    // Category & subcategory configure
    categories: [],
    subCategories: [],
    categoriesService: new CategoryService(),
    loadingCategories: false
  }),

  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: Object,
      default: () => ({})
    }
  },

  watch: {
    'initialData.heading'() {
      this.setInitialData();
    },

    'initialData.passage'() {
      this.setInitialData();
    }
  },

  mounted() {
    if (this.$route.query.isActiveCategory) {
      this.isActiveCategory = this.$route.query.isActiveCategory === 'true';
    }
    this.setInitialData();
    this.loadPassageQuestion().then(() => {
      this.loadQuestions();
      this.loadCategories();
      this.loadPassagePrompts();
    });
  },

  methods: {
    required,
    passageQuestionsValidator,

    proceedQuestion() {
      this.passageQuestion.question = {
        ...this.aiGeneratedQuestion,
        isPassage: true,
        category: this.passageQuestion.category,
        subCategory: this.passageQuestion.sub_category,
        isActive: this.isActiveCategory
      };
      this.showQuestionDialog = true;
    },

    async loadPassagePrompts() {
      try {
        this.promptTemplates = await this.passagePromptsService.fetchAll();

        this.promptTemplates = this.promptTemplates.map((item) => {
          return {
            text: item.title,
            value: item.prompt
          };
        });
      } catch (e) {
        window.console.log(e);
      }
    },

    async generateAiQuestion() {
      this.generateAiQuestionsLoading = true;

      let newPrompt =
        this.passageQuestion.passage + '\n\n' + this.selectedPromptTemplate;

      await fetch(
        'https://us-central1-kings-of-the-curve-sonal.cloudfunctions.net/getOpenAIResponse/kings-of-the-curve-sonal/us-central1/getOpenAIResponse?type=passages&q=true',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            prompt: newPrompt
          })
        }
      )
        .then(async (response) => {
          let data = await response.json();

          this.aiGeneratedQuestion = data?.result[0];
        })
        .catch(() => {
          this.$toast.error('Failed to generate passage question');
        })
        .finally(() => {
          this.generateAiQuestionsLoading = false;
        });
    },

    async loadCategories() {
      this.loadingCategories = true;
      this.categories = this.isActiveCategory
        ? await this.categoriesService.fetchAll()
        : await this.categoriesService.fetchAllInactive();
      for (const item of this.categories) {
        if (this.passageQuestion.category?.id === item.id) {
          this.subCategories = item.subCategories;
          break;
        }
      }
      this.loadingCategories = false;
    },

    reloadPage(url) {
      window.location.href = url;
    },

    close() {
      this.passageQuestion = {
        questions: [{}]
      };

      this.selectedData = {
        images: [],
        videos: [],
        pdfs: []
      };

      this.$emit('close');
    },

    async closeModal() {
      this.showQuestionDialog = false;
      await this.loadQuestions();
    },

    openMediaPickerModal() {
      document.getElementById('mediaPickerBtn').click();
    },

    setSelectedData(data) {
      this.selectedData = {
        images: data.images,
        videos: [],
        pdfs: []
      };
      this.passageQuestion.images = data.images;
    },

    async loadQuestions() {
      this.loadingQuestions = true;
      try {
        this.questions = await this.questionsService.getPassageQuestions(
          this.isActiveCategory === false ? true : false
        );
      } catch (e) {
        window.console.log(e);
      }
      this.loadingQuestions = false;
    },
    async loadPassageQuestion() {
      if (this.isModal) return;
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.passageQuestion = await this.service.fetchOne(this.$route.query.id);
      this.selectedData.images = this.passageQuestion.images;
      if (!this.passageQuestion.htmlPassage)
        this.passageQuestion.htmlPassage = this.passageQuestion.passage;

      if (this.$route.query.reason) {
        this.flagPassageQuestion = { reason: this.$route.query.reason };
      }

      const questions = [];
      this.passageQuestion.questions.forEach((item) => {
        const obj = {
          question: {
            id: item
          }
        };
        questions.push(obj);
      });
      this.passageQuestion.questions = questions;
      this.loading = false;
    },

    setInitialData() {
      if (this.initialData?.passage) {
        this.passageQuestion = {
          ...this.passageQuestion,
          heading: this.initialData.heading,
          passage: this.initialData.passage.join('\n\n')
        };
      }
    },

    async submit(context) {
      const question = { ...this.passageQuestion };

      if (
        this.passageQuestion.images &&
        this.passageQuestion.images.length > 0
      ) {
        question.images = [
          ...this.passageQuestion.images,
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      } else {
        question.images = [
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      }

      const questions = [];
      question.questions.forEach((question) => {
        questions.push(question.question.id);
      });

      question.questions = questions;

      if (this.isEdit) {
        question.category = question.category.id;
        question.sub_category = question.sub_category.id;

        context.changeLoadingMessage('Updating Passage Question');
        try {
          await this.service.update(question);
          this.$toast.success('Passage Question Updated Successfully');
          return true;
        } catch (e) {
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating A New Passage Question');
        try {
          question.category = question.category.id;
          question.sub_category = question.sub_category.id;
          await this.service.create(question);
          this.$toast.success('Passage Question Created Successfully');
          return true;
        } catch (e) {
          window.console.log(e);
          return false;
        }
      }
    },

    removeQuestion(i) {
      if (this.passageQuestion.questions.length > 1) {
        this.passageQuestion.questions.splice(i, 1);
      }
    },
    addQuestion() {
      if (this.passageQuestion.questions.length > 0) {
        this.passageQuestion.questions.push({});
      }
    }
  }
};
</script>
