import axios from 'axios';

export class PopupNotificationsService {
  async fetchAll() {
    // return (await axios.get('/popup-notifications')).data;
    return (await axios.get('/popup-notifications?active=0')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/popup-notifications/' + id)).data;
  }

  async create(data) {
    return (await axios.post('/popup-notifications', data)).data;
  }

  async delete(data) {
    return (await axios.delete('/popup-notifications/' + data.id)).data;
  }

  async update(data) {
    return (await axios.patch('/popup-notifications', data)).data;
  }

  async fetchPopupStats(data) {
    const { id, start_date, end_date } = data;
    return (
      await axios.get(
        `/popup-notifications/stats?popupNId=${id}&startDate=${start_date}&endDate=${end_date}`
      )
    ).data;
  }
}
